import React, { useState } from "react";
import axios from "axios";

function AddItem({ queryPost }) {
  const [taskName, setTaskName] = useState("");
  const [executedBy, setExecutedBy] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://taskmanagementproject.herokuapp.com/posts/", {
        taskName,
        executedBy,
        start_date,
        end_date,
      })
      .then((res) => queryPost());
  };

  return (
    <div>
      <div className="mt-14 flex w-full flex-col text-center">
        <h1 className="title-font mb-4 text-2xl font-medium text-gray-900 sm:text-3xl">
          MAOU Task Management
        </h1>
      </div>
      <div className="mx-12 pt-6">
        <button
          className="block rounded-lg bg-blue-700 px-3 py-1.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
          data-modal-toggle="authentication-modal"
        >
          Add
        </button>
      </div>
      <div
        id="authentication-modal"
        aria-hidden="true"
        className="fixed right-0 left-0 top-4 z-50 hidden h-modal items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0 md:h-full"
      >
        <div className="relative h-full w-full max-w-md px-4 md:h-auto">
          <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
            <div className="flex justify-end p-2">
              <button
                type="button"
                className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
              >
                <svg
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <form
              className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
              action="/#"
              onSubmit={onSubmit}
            >
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Task description
              </h3>
              {/* Task Name */}
              <div>
                <label
                  htmlFor="Task"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Task Name
                </label>
                <input
                  type="text"
                  name="taskName"
                  id="text"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                  placeholder="TVC marketing"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  required
                />
              </div>

              {/* Executed by */}
              <div>
                <label
                  htmlFor="executedBy"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Executed By
                </label>
                <input
                  type="text"
                  name="executedBy"
                  id="executedBy"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                  placeholder="Omicron"
                  value={executedBy}
                  onChange={(e) => setExecutedBy(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="Task"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Task Duration
                </label>
                <div date-rangepicker="true" className="flex items-center">
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <svg
                        className="h-5 w-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      name="startDate"
                      type="text"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600  dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      placeholder="mm/dd/yy"
                      value={start_date}
                      onChange={(e) => setStartDate(e.target.value)}
                      required
                    />
                  </div>
                  <span className="mx-4 text-gray-500">to</span>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <svg
                        className="h-5 w-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                    <input
                      name="end_date"
                      type="text"
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600  dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
                      placeholder="mm/dd/yy"
                      value={end_date}
                      onChange={(e) => setEndDate(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>

              {/* submit btn */}
              <button
                type="submit"
                className="focus:ring-white-300 dark:focus:ring-white-800 w-full rounded-lg bg-green-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:ring-4 dark:bg-green-600 dark:hover:bg-green-700"
                value="Submit"
              >
                CONFIRM
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddItem;
