import axios from "axios";
import React, { useState } from "react";
import FormEdit from "../utils/form";

function AddItem({ p_post }) {
  const [formAddClicked, setFormAddClicked] = useState(false);

  const onSubmit = (e, data) => {
    e.preventDefault();
    axios
      .post("https://testinguser.herokuapp.com/posts", {
        employee: data.employee,
        role: data.role,
        email: data.email,
        status: data.status,
      })
      .then(() => {
        p_post();
      });
  };

  const addFormProps = {
    title: "Add employee",
    isOpen: formAddClicked,
    setOpen: setFormAddClicked,
    initialValues: {
      employee: "",
      role: "",
      email: "",
      status: "",
    },
  };

  return (
    <div>
      <div className="mt-14 flex w-full flex-col text-center">
        <h1 className="title-font mb-4 text-2xl font-medium text-gray-900 sm:text-3xl">
          MAOU Staff Management
        </h1>
        <p className="mx-auto text-base leading-relaxed lg:w-2/3">
          Employee information
        </p>
      </div>
      <div className="pt-6 pl-8">
        <button
          onClick={() => setFormAddClicked(true)}
          className="block rounded-lg bg-indigo-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-indigo-800 focus:ring-4 focus:ring-purple-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="inline-block h-5 w-5 pb-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
          </svg>
          Add
        </button>
        <FormEdit {...addFormProps} onFormSubmit={onSubmit} />
      </div>
    </div>
  );
}

export default AddItem;
