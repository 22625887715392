import axios from "axios";
import { useState } from "react";
import FormEdit from "../utils/form";

function StaffList({ posts = [], ...rest }) {
  const [isEditOpen, setEditOpen] = useState(false);
  const [currentEditData, setCurrentEditData] = useState(null);

  const handleEditClick = (post) => {
    setEditOpen(true);
    setCurrentEditData(post);
  };

  const handleEmployeeDelete = async (employee_id) => {
    const { p_post } = rest;
    await axios.delete(
      "https://testinguser.herokuapp.com/posts/" + employee_id
    );
    p_post();
  };

  const formEditProps = {
    title: "Edit employee",
    isOpen: isEditOpen,
    setOpen: setEditOpen,
    initialValues: currentEditData,
  };

  const th = ["ID", "Employee", "Role", "Email"];

  return (
    <>
      <div className="flex flex-col pt-2 ">
        <div className="inline-block min-w-full py-2 sm:px-4 lg:px-8">
          <div className="overflow-hidden shadow-md sm:rounded-lg">
            <table className="min-w-full">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr className=" bg-indigo-400">
                  <th
                    scope="col"
                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Employee
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Role
                  </th>

                  <th
                    scope="col"
                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Edit
                  </th>

                  <th
                    scope="col"
                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Delete
                  </th>
                </tr>
              </thead>

              <tbody>
                {posts?.map((post) => (
                  <tr
                    key={post.id}
                    className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <td className="display:hidden whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                      {post.id}
                    </td>
                    <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-white">
                      {post.employee}
                    </td>
                    <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-white">
                      {post.role}
                    </td>
                    <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-white">
                      {post.email}
                    </td>
                    <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-white">
                      {post.status}
                    </td>

                    {/* Edit */}
                    <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-white">
                      <button
                        className="rounded border border-blue-500 bg-transparent py-2 px-4 font-semibold text-blue-700 hover:border-transparent hover:bg-blue-500 hover:text-white dark:bg-blue-300"
                        onClick={() => handleEditClick(post)}
                      >
                        Edit
                      </button>
                    </td>

                    <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-white">
                      <button
                        className="rounded border border-red-500 bg-transparent py-2 px-4 font-semibold text-red-700 hover:border-transparent hover:bg-red-500 hover:text-white dark:bg-red-300"
                        onClick={() => handleEmployeeDelete(post.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isEditOpen && <FormEdit {...formEditProps} {...rest} />}
    </>
  );
}

export default StaffList;
