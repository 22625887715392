const Register = () => {
  return (
    <section className="flex h-screen items-center justify-center bg-gray-700">
      <div className="mx-auto max-w-4xl rounded-md bg-white p-6 shadow-md">
        <h2 className="text-lg font-semibold capitalize text-gray-700">
          Register your Account
        </h2>
        <form>
          <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="text-gray-700" for="username">
                Username
              </label>
              <input
                id="username"
                type="text"
                className="-300 mt-2 block w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300    focus:ring-opacity-40"
              />
            </div>
            <div>
              <label className="text-gray-700" for="emailAddress">
                Email Address
              </label>
              <input
                id="emailAddress"
                type="email"
                className="-300 mt-2 block w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300    focus:ring-opacity-40"
              />
            </div>
            <div>
              <label className="text-gray-700" for="password">
                Password
              </label>
              <input
                id="password"
                type="password"
                className="-300 mt-2 block w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300    focus:ring-opacity-40"
              />
            </div>
            <div>
              <label className="text-gray-700" for="passwordConfirmation">
                Password Confirmation
              </label>
              <input
                id="passwordConfirmation"
                type="password"
                className="-300 mt-2 block w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300    focus:ring-opacity-40"
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button className="transform rounded-md bg-gray-700 px-6 py-2 leading-5 text-white transition-colors duration-200 hover:bg-gray-600 focus:bg-gray-600 focus:outline-none">
              Save
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Register;
