import Profile from "./Profile";
import daravid from "../assets/daravid.JPG";
import cheahy from "../assets/cheahy.jpeg";
import chhengnay from "../assets/Pika.jpeg";
import rith from "../assets/rith.jpg";

const Teams = () => {
  return (
    <section className="body-font text-gray-600">
      <div className="container mx-auto px-5 py-24">
        <div className="mb-20 flex w-full flex-col text-center">
          <h1 className="title-font mb-4 text-2xl font-medium tracking-widest text-gray-900">
            OUR TEAM
          </h1>
          <p className="mx-auto text-base leading-relaxed lg:w-2/3">
            Introducing our team members of passionate developers from Paragon
            International University.
          </p>
        </div>
        <div className="m-4 flex flex-wrap justify-center">
          <Profile
            name="Cheahy Lao"
            email="clao1@paragoniu.edu.kh"
            desc={`"Don't give up on Your Dreams, Keep Sleeping."`}
            image={cheahy}
          />
          <Profile
            name="Chhengnay Ich"
            email="cich@paragoniu.edu.kh"
            desc='"10 hours of debugging can save us 10 mins of reading documentation."'
            image={chhengnay}
          />
          <Profile
            name="Daravid Ngauv"
            email="dngauv@paragoniu.edu.kh"
            image={daravid}
            desc='"let your past be your past so that the future is your own. Keep striving!"'
          />
          <Profile
            name="Sovannpanharith Sak"
            email="ssak@paragoniu.edu.kh"
            desc={`"Life is not about trying to be the best, it's about being the best of yourself."`}
            image={rith}
          />
        </div>
      </div>
    </section>
  );
};

export default Teams;
