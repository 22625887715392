import axios from "axios";
import AddItem from "./Component/Staff/Add";
import StaffList from "./Component/Staff/Table";
import { useState, useEffect } from "react";
import Nav from "./components/Nav";

const Staffs = () => {
  const [posts, setPosts] = useState([]);
  const getPosts = () => {
    axios
      .get("https://testinguser.herokuapp.com/posts")
      .then((res) => setPosts(res.data));
  };

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <>
      <Nav />
      <AddItem p_post={getPosts} />
      <StaffList posts={posts} p_post={getPosts} />
    </>
  );
};

export default Staffs;
