const Button = (props) => {
  if (props.border === "none") {
    return (
      <button className="mx-2 rounded py-2 px-6 text-lg uppercase duration-500 ease-in-out hover:scale-110 hover:text-xl dark:bg-gray-300">
        {props.children}
      </button>
    );
  }
  return (
    <button
      className={`${
        props.type === "primary"
          ? "bg-indigo-600 text-white hover:bg-indigo-400"
          : "border-2 border-indigo-600 text-gray-800 hover:bg-indigo-600 hover:text-white"
      } mx-2 rounded py-2 px-6 text-lg uppercase shadow duration-500 ease-in-out hover:scale-110 hover:text-xl`}
    >
      {props.children}
    </button>
  );
};

export default Button;
