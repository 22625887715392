import Header from "./components/Header";
import Nav from "./components/Nav";
import Teams from "./components/Teams";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Statistic from "./components/Statistic";

function App() {
  return (
    <>
      <header>
        <Nav />
      </header>
      <main>
        <Header />
        <Statistic />
        <Teams />
        <Contact />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
