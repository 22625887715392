import Button from "../components/Button";
import { IoChevronForward } from "react-icons/io5";
import dashboard from "../assets/dashboard.jpg";

const Header = () => {
  return (
    <section className="body-font text-gray-600">
      <div className="container mx-auto flex flex-col items-center px-5 py-24 md:flex-row">
        <div className="mb-16 flex flex-col items-center text-center md:mb-0 md:w-1/2 md:items-start md:pr-16 md:text-left lg:flex-grow lg:pr-24">
          <h1 className="title-font mb-4 text-3xl font-medium text-gray-900 sm:text-4xl">
            Dashboard for your business
          </h1>
          <p className="mb-8 leading-relaxed">
            Display your staff working hours and tasks in an easy to understand
            way. Each task is displayed as a card that can easily add, edit, and
            delete.
          </p>
          <div className="flex justify-center">
            <Button type="primary">Join Now</Button>
            <Button border="none">
              Learn More <IoChevronForward className="inline" />
            </Button>
          </div>
        </div>
        <div className="w-5/6 md:w-1/2 lg:w-full lg:max-w-lg">
          <img
            className="rounded object-cover object-center"
            alt="hero"
            src={dashboard}
          />
        </div>
      </div>
    </section>
  );
};
export default Header;
