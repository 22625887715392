const Card = (props) => {
  return (
    <div className="w-full p-4 sm:w-1/2 md:w-1/4">
      <div className="rounded-lg border-2 border-gray-200 px-4 py-6">
        {props.children}
        <h2 className="title-font text-3xl font-medium text-gray-900">
          {props.desc}
        </h2>
        <p className="leading-relaxed">{props.name}</p>
      </div>
    </div>
  );
};

export default Card;
