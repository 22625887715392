function TaskTable({ posts = [] }) {
  return (
    <div>
      {/* <AllPosts /> */}
      <div className="flex flex-col px-10">
        <div className="overflow-x-auto sm:-mx-2 lg:-mx-6">
          <div className="inline-block min-w-full py-2 sm:px-4 lg:px-8">
            <div className="overflow-hidden shadow-md sm:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  {/* Table row */}
                  <tr>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700 dark:text-gray-400"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700 dark:text-gray-400"
                    >
                      Task Name
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700 dark:text-gray-400"
                    >
                      Executed by
                    </th>
                    {/* <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Status
                    </th> */}
                    <th
                      scope="col"
                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700 dark:text-gray-400"
                    >
                      Start Date
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700 dark:text-gray-400"
                    >
                      End Date
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody>
                  {/* <!-- Product 1 --> */}
                  {posts?.map((post) => (
                    <tr
                      key={post.id}
                      className="border-b bg-white dark:border-gray-700 dark:bg-gray-800"
                    >
                      <td className="display:hidden whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 dark:text-white">
                        {post.id}
                      </td>
                      <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {post.taskName}
                      </td>
                      <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {post.executedBy}
                      </td>
                      <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {post.start_date}
                      </td>
                      <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 dark:text-gray-400">
                        {post.end_date}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskTable;
