import { useState, useEffect } from "react";
import axios from "axios";
import AddItem from "./Component/Task/Add";
import TaskTable from "./Component/Task/Table";
import Nav from "./components/Nav";

const Tasks = () => {
  const [posts, setPosts] = useState([]);
  const getPosts = () => {
    axios
      .get("https://taskmanagementproject.herokuapp.com/posts") /// --> This is keep things clear. I used the .env to declare environment variable for easy usage
      .then((res) => setPosts(res.data)); // --> when the data is returned set the posts state to the new array of data
  };
  useEffect(() => {
    getPosts();
  }, []);
  return (
    <>
      <Nav />
      <AddItem queryPost={getPosts} />
      <TaskTable posts={posts} />
    </>
  );
};

export default Tasks;
