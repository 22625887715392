import React, { useState } from "react";
import axios from "axios";

function FormEdit({
  title,
  isOpen,
  setOpen,
  initialValues,
  p_post,
  onFormSubmit = null,
}) {
  const [employee, setEmployee] = useState(initialValues.employee);
  const [role, setRole] = useState(initialValues.role || "Manager");
  const [email, setEmail] = useState(initialValues.email);
  const [status, setStatus] = useState(initialValues.status);

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`https://testinguser.herokuapp.com/posts/${initialValues.id}`, {
        employee,
        role,
        email,
        status,
      })
      .then(() => {
        p_post();
      });
  };

  return (
    <div>
      <div className="pt-3 pl-2">
        <div
          aria-hidden="true"
          className={`${
            !isOpen ? "hidden" : "flex"
          } h-modal fixed right-0 left-0 top-4 z-50 items-center justify-center overflow-y-auto overflow-x-hidden md:inset-0 md:h-full`}
        >
          <div className="relative h-full w-full max-w-md px-4 md:h-auto">
            <div className="relative rounded-lg bg-white shadow dark:bg-gray-700">
              <div className="flex justify-end p-2">
                <button
                  onClick={() => setOpen(false)}
                  type="button"
                  className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white"
                >
                  <svg
                    className="h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <form
                className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8"
                onSubmit={(e) => {
                  onFormSubmit
                    ? onFormSubmit(e, {
                        employee,
                        role,
                        email,
                        status,
                      })
                    : onSubmit(e);
                }}
              >
                <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                  {title}
                </h3>

                <div>
                  <label
                    htmlFor="employee"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Employee
                  </label>
                  <input
                    type="text"
                    name="employee"
                    id="text"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                    placeholder="Name"
                    defaultValue={employee}
                    onChange={(e) => setEmployee(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="role"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Role
                  </label>
                  <input
                    type="text"
                    name="role"
                    id="role"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                    placeholder="i.e Manager"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                    placeholder="i.e pika@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="status"
                    className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Status
                  </label>
                  <input
                    type="text"
                    name="status"
                    id="status"
                    className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:text-white dark:placeholder-gray-400"
                    placeholder="i.e Inactive"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                  />
                </div>

                <button
                  type="submit"
                  className="focus:ring-white-300 dark:focus:ring-white-800 w-full rounded-lg bg-indigo-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-indigo-800 focus:ring-4 dark:bg-green-600 dark:hover:bg-green-700"
                  value="Submit"
                >
                  CONFIRM
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div
          modal-backdrop=""
          className="fixed inset-0 z-40 bg-gray-900 bg-opacity-50 dark:bg-opacity-80"
        ></div>
      )}
    </div>
  );
}

export default FormEdit;
