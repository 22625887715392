import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Staffs from "./Staffs";
import Login from "./Login";
import Register from "./Register";
import Report from "./Report";
import Tasks from "./Tasks";
import "flowbite";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="Tasks" element={<Tasks />} />
        <Route path="Report" element={<Report />} />
        <Route path="Staffs" element={<Staffs />} />
        <Route path="Login" element={<Login />} />
        <Route path="Register" element={<Register />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
