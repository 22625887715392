import React from "react";
import MaterialTable from "material-table";
import Nav from "./components/Nav";

const staffData = [
  {
    id: 1,
    Task_Name: "Fixing bug",
    Executed_by: "So Vichea",
    Start_date: "13.01.2022",
    End_date: "14.01.2022",
  },
  {
    id: 2,
    Task_Name: "Update Data",
    Executed_by: "Sok Theara",
    Start_date: "17.01.2022",
    End_date: "24.01.2022",
  },
  {
    id: 3,
    Task_Name: "Merging",
    Executed_by: "Om Vibol",
    Start_date: "18.02.2022",
    End_date: "25.02.2022",
  },
  {
    id: 4,
    Task_Name: "Fixing bug",
    Executed_by: "Kuy Pisey",
    Start_date: "13.04.2021",
    End_date: "12.07.2021",
  },
  {
    id: 5,
    Task_Name: "Merging",
    Executed_by: "Sok Ly",
    Start_date: "18.02.2022",
    End_date: "18.02.2022",
  },
  {
    id: 6,
    Task_Name: "Small Change",
    Executed_by: "Srun Pitu",
    Start_date: "13.04.2021",
    End_date: "31.12.2021",
  },
  {
    id: 7,
    Task_Name: "Fix Bug",
    Executed_by: "Kuy Pisey",
    Start_date: "03.01.2022",
    End_date: "15.02.2022",
  },
  {
    id: 8,
    Task_Name: "Merging",
    Executed_by: "Srun Pitu",
    Start_date: "15.02.2022",
    End_date: "28.02.2022",
  },
  {
    id: 9,
    Task_Name: "Installation",
    Executed_by: "Om Vibol",
    Start_date: "11.01.2022",
    End_date: "04.02.2022",
  },
  {
    id: 10,
    Task_Name: "Fix Bug",
    Executed_by: "Huy Puthi",
    Start_date: "01.02.2022",
    End_date: "25.02.2022",
  },
  {
    id: 11,
    Task_Name: "Merging",
    Executed_by: "Kuy Pisey",
    Start_date: "18.02.2022",
    End_date: "25.02.2022",
  },
  {
    id: 12,
    Task_Name: "Installation",
    Executed_by: "Srun Pitu",
    Start_date: "02.02.2022",
    End_date: "04.02.2022",
  },
  {
    id: 13,
    Task_Name: "Installation",
    Executed_by: "Sok Ly",
    Start_date: "05.02.2022",
    End_date: "14.02.2022",
  },
  {
    id: 14,
    Task_Name: "Fix Bug",
    Executed_by: "Om Vibol",
    Start_date: "09.08.2021",
    End_date: "04.02.2022",
  },
  {
    id: 15,
    Task_Name: "Merge",
    Executed_by: "Sok Theara",
    Start_date: "14.05.2021",
    End_date: "19.06.2021",
  },
  {
    id: 16,
    Task_Name: "Duplicate",
    Executed_by: "Sok Vira",
    Start_date: "17.08.2021",
    End_date: "11.10.2021",
  },
  {
    id: 17,
    Task_Name: "Installation",
    Executed_by: "Kong Hean",
    Start_date: "15.02.2021",
    End_date: "04.09.2021",
  },
  {
    id: 18,
    Task_Name: "Fix Bug",
    Executed_by: "Keo Nama",
    Start_date: "02.02.2022",
    End_date: "04.02.2022",
  },
  {
    id: 19,
    Task_Name: "Remove Program",
    Executed_by: "Sok Ly",
    Start_date: "15.07.2021",
    End_date: "06.09.2021",
  },
  {
    id: 20,
    Task_Name: "Installation",
    Executed_by: "Eran Young",
    Start_date: "12.09.2021",
    End_date: "21.12.2021",
  },
  {
    id: 21,
    Task_Name: "Fix Bug",
    Executed_by: "Srun Pitu",
    Start_date: "12.10.2021",
    End_date: "30.11.2021",
  },
];

function Report() {
  let XLSX = require("xlsx");
  const columns = [
    { title: "Task Name", field: "Task_Name" },
    { title: "Executed by", field: "Executed_by" },
    { title: "Start date", field: "Start_date", type: "date" },
    { title: "End date", field: "End_date", type: "date" },
  ];

  const downloadExcel = () => {
    const newData = staffData.map((row) => {
      delete row.tableData;
      return row;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "students");
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Report_Data.xlsx");
  };
  return (
    <>
      <Nav />
      <div className="my-20 flex w-full flex-col text-center">
        <h1 className="title-font mb-4 text-2xl font-medium text-gray-900 sm:text-3xl">
          MAOU Report History
        </h1>
        <p className="mx-auto text-base leading-relaxed lg:w-2/3">
          Report information by employee
        </p>
      </div>
      <MaterialTable
        title="Report Details"
        columns={columns}
        data={staffData}
        actions={[
          {
            icon: () => <button>Export</button>, // you can pass icon too
            tooltip: "Export to Excel",
            onClick: () => downloadExcel(),
            isFreeAction: true,
          },
        ]}
      />
    </>
  );
}

export default Report;
