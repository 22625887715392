import Button from "./Button";
import logo from "../assets/WhiteBGLogo.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import { IoCloseOutline, IoMenuOutline } from "react-icons/io5";

const Nav = () => {
  let [isOpen, setIsOpen] = useState(false);
  let links = [
    { name: "Home", href: "/" },
    { name: "Tasks", href: "/Tasks" },
    { name: "Report", href: "/Report" },
    { name: "Staffs", href: "/Staffs" },
  ];
  return (
    <div className="flex flex-wrap items-center justify-between bg-gray-100 px-8">
      <Link to="/" className="flex items-center">
        <img src={logo} alt="Maou's Logo" className="w-20" />
        <p className="text-2xl font-medium uppercase">Maou</p>
      </Link>
      <nav>
        <ul className="hidden md:flex">
          {links.map((link) => (
            <Link
              to={link.href}
              key={link.name}
              className="ml-8 p-0 text-xl text-gray-700 last:mr-8"
            >
              {link.name}
            </Link>
          ))}
        </ul>
        {isOpen && (
          <ul className="absolute left-0 top-20 flex w-full flex-col border border-gray-200 md:hidden">
            {links.map((link) => (
              <Link
                to={link.href}
                key={link.name}
                className="z-10 w-full bg-gray-100 px-10 py-5 text-xl text-gray-700 hover:border-2 hover:border-gray-200 hover:text-2xl md:ml-8 md:p-0 md:last:mr-8"
              >
                {link.name}
              </Link>
            ))}
          </ul>
        )}
      </nav>
      <div className="hidden md:flex">
        <Link to="/Login">
          <Button>Login</Button>
        </Link>
        <Link to="/Register">
          <Button type="primary">Register</Button>
        </Link>
      </div>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="rounded border-2 border-gray-600/50 p-1 md:hidden"
      >
        {isOpen ? <IoCloseOutline size={30} /> : <IoMenuOutline size={30} />}
      </div>
    </div>
  );
};

export default Nav;
