const Profile = (props) => {
  return (
    <div className="p-4 lg:w-1/2">
      <div className="flex h-full flex-col items-center justify-center text-center sm:flex-row sm:justify-start sm:text-left">
        <img
          alt="team"
          className="mb-4 h-48 w-48 flex-shrink-0 rounded-lg object-cover object-center sm:mb-0"
          src={
            props.image != null ? props.image : "https://dummyimage.com/204x204"
          }
        />
        <div className="flex-grow sm:pl-8">
          <h2 className="title-font text-lg font-medium text-gray-900">
            {props.name}
          </h2>
          <h3 className="mb-3 text-gray-500">{props.email}</h3>
          <p className="mb-4">{props.desc}</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
